import React, { Fragment } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"


class GMember extends React.Component<{data, children, node, props}>  {

  state = {isRead: false};
  handleClick = () => {
    this.setState({isRead: !this.state.isRead})
  }
  
  render() {
    const { children, data, props, node } = this.props;
    
    return (
      <div className="memb__cont">
        <div className="memb__head">
          <div className="memb__img">
            <img src={node.memberImage.file.url} alt="" />
          </div>
          <div className="memb__info">
            <h5 className="memb__name">{node.memberFullName}</h5>
            <div className="memb__titles">{node.memberTitles}</div>
          </div>
        </div>
        <div className="memb__body">
          { /*<div className="memb__body__row">
            <h6 className="memb__body__title">Worked with:</h6>
            <div className="memb__body__text" dangerouslySetInnerHTML={{ __html: node.membersWorked.childMarkdownRemark.html }} />
          </div>*/ }
          <div className="memb__body__row">
            <h6 className="memb__body__title">Biography:</h6>
            <div className={this.state.isRead ? "text__cont text--collapse text--collapse--active" : 'text__cont text--collapse'}>
              <div className="memb__body__text" dangerouslySetInnerHTML={{ __html: node.memberBio.childMarkdownRemark.html }} />
              <span onClick={this.handleClick} className="text--collapse__btn">Show {this.state.isRead ? "Less" : 'More'}  <span className="i i-arw-t"></span></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// const GMember = ({node}, props) => {
//   return (
//     <div className="memb__cont">
//       <div className="memb__head">
//         <div className="memb__img">
//           <img src={node.memberImage.file.url} alt="" />
//         </div>
//         <div className="memb__info">
//           <h5 className="memb__name">{node.memberFullName}</h5>
//           <div className="memb__titles">{node.memberTitles}</div>
//         </div>
//       </div>
//       <div className="memb__body">
//         { /*<div className="memb__body__row">
//           <h6 className="memb__body__title">Worked with:</h6>
//           <div className="memb__body__text" dangerouslySetInnerHTML={{ __html: node.membersWorked.childMarkdownRemark.html }} />
//         </div>*/ }
//         <div className="memb__body__row">
//           <h6 className="memb__body__title">Biography:</h6>
//           <div className="text__cont text--collapse">
//             <div className="memb__body__text" dangerouslySetInnerHTML={{ __html: node.memberBio.childMarkdownRemark.html }} />
//             <div className="text--collapse__btn"><a href="" className="btn btn--prim">Show less <span className="i i-arw-t"></span></a></div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export default (props) => {
  const data = useStaticQuery(graphql`
    query GroupMemberQuery {
      allContentfulGroupMember {
        edges {
          node {
            memberImage {
              file {
                url
              }
            }
            memberFullName
            memberTitles
            memberBio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const gMembers = data.allContentfulGroupMember.edges
  const gMembersLength = gMembers.length
  return (
    <div className="cont__row">
      <div className="cont--grid">
        {gMembers.map((items, index) => (
          <GMember node={items.node} />
        ))}
      </div>
    </div>
  )
}
